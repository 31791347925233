import React, { useEffect, useState } from 'react';
import { Translations } from '../core__links-header-app';
import {
  IMG,
  WRAPPER,
  LINK,
  LINK_TEXT,
} from 'UI/apps/LinksHeaderApp/LinksHeader';
import Constants from '../../../../project';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import FSBCustomerSession from 'Services/session/models/core__session.models.fsb';
import PropTypes from 'prop-types';

const LinksHeaderReact = ({ appConfig }) => {
  const [session, setSession] = useState(false);
  const {
    linkfavourites,
    linkchallenges,
    linkblackjack,
    linkrewards,
    linkroulette,
    linkshop,
    linkslots,
    hiderewardsandshop,
    hidechallengesbutton,
    hideshopbutton,
  } = appConfig || {};
  const favouriteArr = linkfavourites?.split(',');
  const challengesArr = linkchallenges?.split(',');
  const blackjackArr = linkblackjack?.split(',');
  const rewardsArr = linkrewards?.split(',');
  const rouletteArr = linkroulette?.split(',');
  const shopArr = linkshop?.split(',');
  const slotsArr = linkslots?.split(',');

  PubSub.listen(PubsubEvents.SESSION_CREATE, () => {
    setSession(true);
  });

  PubSub.listen(PubsubEvents.SESSION_UPDATED, () => {
    setSession(FSBCustomerSession?.accessToken);
  });

  PubSub.listen(PubsubEvents.SESSION_DESTROY, () => {
    setSession(false);
  });

  useEffect(() => {
    setSession(FSBCustomerSession?.accessToken);
  }, []);

  return (
    <WRAPPER className="topheader-middle" id="topheader-midd">
      {session && (
        <LINK
          id="topheader-mobile"
          className="topheader-middle--link"
          href={favouriteArr[0] && favouriteArr[0]}
        >
          <IMG src={`${Constants.staticPath.basePath}${favouriteArr[1]}`} />
          {favouriteArr[2] && (
            <LINK_TEXT>{Translations.get(favouriteArr[2])}</LINK_TEXT>
          )}
        </LINK>
      )}
      <LINK
        id="topheader-mobile"
        className="topheader-middle--link"
        href={slotsArr[0] && slotsArr[0]}
      >
        <IMG src={`${Constants.staticPath.basePath}${slotsArr[1]}`} />
        {slotsArr[2] && <LINK_TEXT>{Translations.get(slotsArr[2])}</LINK_TEXT>}
      </LINK>
      <LINK
        id="topheader-mobile"
        className="topheader-middle--link"
        href={blackjackArr[0] && blackjackArr[0]}
      >
        <IMG src={`${Constants.staticPath.basePath}${blackjackArr[1]}`} />
        {blackjackArr[2] && (
          <LINK_TEXT>{Translations.get(blackjackArr[2])}</LINK_TEXT>
        )}
      </LINK>
      <LINK
        id="topheader-mobile"
        className="topheader-middle--link"
        href={rouletteArr[0] && rouletteArr[0]}
      >
        <IMG src={`${Constants.staticPath.basePath}${rouletteArr[1]}`} />
        {rouletteArr[2] && (
          <LINK_TEXT>{Translations.get(rouletteArr[2])}</LINK_TEXT>
        )}
      </LINK>
      {!hiderewardsandshop && (
        <React.Fragment>
          <LINK
            id="topheader-mobile"
            className="topheader-middle--link"
            href={session ? rewardsArr[0] : '/loyalty/intro/'}
          >
            <IMG src={`${Constants.staticPath.basePath}${rewardsArr[1]}`} />
            {rewardsArr[2] && (
              <LINK_TEXT>{Translations.get(rewardsArr[2])}</LINK_TEXT>
            )}
          </LINK>

          {session && !hideshopbutton && shopArr && (
            <LINK
              id="topheader-mobile"
              className="topheader-middle--link"
              href={`${shopArr[0] && shopArr[0]}`}
            >
              <IMG src={`${Constants.staticPath.basePath}${shopArr[1]}`} />
              {shopArr[2] && (
                <LINK_TEXT>{Translations.get(shopArr[2])}</LINK_TEXT>
              )}
            </LINK>
          )}
        </React.Fragment>
      )}

      {session && !hidechallengesbutton && challengesArr && (
        <LINK
          id="topheader-mobile"
          className="topheader-middle--link"
          href={challengesArr[0] && challengesArr[0]}
        >
          <IMG src={`${Constants.staticPath.basePath}${challengesArr[1]}`} />
          {challengesArr[2] && (
            <LINK_TEXT>{Translations.get(challengesArr[2])}</LINK_TEXT>
          )}
        </LINK>
      )}
    </WRAPPER>
  );
};
LinksHeaderReact.propTypes = {
  appConfig: PropTypes.object,
};

export default LinksHeaderReact;
