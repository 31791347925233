import React from 'react';
import ReactDOM from 'react-dom';
import ScDecoder from 'Services/json/core__decoder';
import LinksHeaderReact from './component/core__linksHeaderReact';
import { TranslationsProvider } from 'Services/translations/core__translations';
import { translationString } from './core__links-header-translation';
export const Translations = new TranslationsProvider('LinksHeaderApp');

class LinksHeaderApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }

    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    Translations.setAll(data);

    const ReactInstance = this.getReactApp();
    const jsx = <ReactInstance appConfig={this.appConfig} />;

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    LinksHeaderReact.strToTranslate = translationString;
    return LinksHeaderReact;
  }
}

export default LinksHeaderApp;
